import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';

let styles = {
    display: 'flex',
    fontWeight: '500',
    width: '125px',
    height: '25px',
    borderRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '10px 0',
    imgStye: { 
        margin: '5px', 
        width: '17px', 
        color: 'white' 
    }
}

const icons = {
    Pendiente: <ReportProblemOutlinedIcon style={styles.imgStye} />,
    Procesado: <CheckCircleOutlinedIcon style={styles.imgStye} />,
    Enviado: <SendOutlinedIcon style={styles.imgStye} />,
    Recibido: <InboxOutlinedIcon style={styles.imgStye} />,
    Programado: <EventOutlinedIcon style={styles.imgStye} />,
    Cancelado: <ClearIcon style={styles.imgStye} />,
    Modificacion: <Edit style={styles.imgStye} />
}

export const WarehouseOutputStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Pendiente} Pendiente
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Procesado} Procesado
                </div>
            )
    }
}

export const WarehouseShipmentStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Enviado
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Recibido} Recibido
                </div>
            )
        case 2:
            styles.backgroundColor = '#FF4D4F'
            return (
                <div style={{ ...styles }}>
                    {icons.Cancelado} Cancelado
                </div>
            )
    }
}
    
export const OutboundShipmentStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 0:
            styles.backgroundColor = '#54C0F9'
            return (
                <div style={{ ...styles }}>
                    {icons.Programado} Programado
                </div>
            )
        case 1:
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Enviado
                </div>
            )
    }
}

export const MovementsStates = (state)=> {
    // eslint-disable-next-line default-case
    switch (state) {
        case 'MODIFICACION':
            styles.backgroundColor = '#ffba00'
            return (
                <div style={{ ...styles }}>
                    {icons.Modificacion} Modificación
                </div>
            )
        case "CANCELACION":
            styles.backgroundColor = '#FF4D4F'
            return (
                <div style={{ ...styles }}>
                    {icons.Cancelado} Cancelado
                </div>
            )
        case "RECEPCION":
            styles.backgroundColor = '#5FCE8C'
            return (
                <div style={{ ...styles }}>
                    {icons.Recibido} Recepción
                </div>
            )
        case 'ENVIO':
            styles.backgroundColor = '#54c0f9'
            return (
                <div style={{ ...styles }}>
                    {icons.Enviado} Envío
                </div>
            )
    }
}

