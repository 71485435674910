import { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { prodcutsColumns } from '../../components/tables/schemaColumn'
import { productsDownloads } from '../../components/tables/schemaDownload'
import { useHistory } from "react-router-dom";
import { Button } from 'antd';
import { ProfileOutlined } from '@ant-design/icons'
import ModalContainer from '../quoter/containers/ModalContainer';
import ProductSelector from '../../components/generals/ProductSelector'

const ITEMS_PER_PAGES = 150

const ActiveProducts = () => {
  const { getTokenSilently } = useAuth0();
  const History = useHistory();

  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('') // string
  const [dataColumns, setDataColumns] = useState(null) // any 
  const [activateDelete, setActivateDelete] = useState(false)
  const [downloadColumns] = useState({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    isAdmin: false,
    columns: [
      {
        id: 'SKUCliente',
        find: false
      },
      {
        id: 'Nombre',
        find: false
      },
      {
        id: 'Descripcion',
        find: false
      },
      {
        id: 'ValorMercancia',
        find: false
      },
      {
        id: 'PesoKG',
        find: false
      },
      {
        id: 'Largo',
        find: false
      },
      {
        id: 'Ancho',
        find: false
      },
      {
        id: 'Altura',
        find: false
      },
      {
        id: 'ValorMercancia',
        find: false
      },
      {
        id: 'Visible',
        find: true,
        filters: {
          sort: false,
          filter: true,
          filter_values: [true] 
        }
      },
      
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })
  const [massiveDownload, setMassiveDownload] = useState(false)

  const handleReload = async() => {
    setActivateDelete(!activateDelete)
  }

  const handleMassiveDownloadModal = ()=> {
    setMassiveDownload(!massiveDownload)
  }

  const handleFecthing = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/allproducts`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    if (saveState){
      setDataColumns(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthing(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns, activateDelete])

  return (
    <>
      <Table
        // title={"Productos"}
        tableType="products"
        data={dataColumns ?? []}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={productsDownloads}
        columns={prodcutsColumns("active", handleReload) ?? []}
        config={{ disableFilters: true }}
        optionalContent={{
          options: (
            <>
              <div className="downloadXLSX">
                  <Button
                    onClick={() => setMassiveDownload(!massiveDownload)}
                    style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                    icon={<ProfileOutlined />}
                  >
                    Descargar guias masivas
                  </Button>
              </div>
              <Button
                type="primary"
                onClick={() => History.push("/products/create")}
              >
                Agregar Producto
              </Button>
            </>
          )
        }}
        stateValues={{
          currentPage,
          rowsPerPages,
          topLimit,
          searchText,
          filterByColumns,
          downloadColumns,
          newProspectModal: false,
        }}
        filters={{
          filters: ['date'],
          filterBySearchText(text) { // string //
            setSearchText(text)
          },
          filterByColumns(filter) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter) => {
            return await handleFecthing(filter, false)
          },
        }}
      />
      <ModalContainer
        height = '100%'
        visible={massiveDownload}
        title="Descarga masiva de codigos QR"
        onCancel={()=> setMassiveDownload(false)}
        onOk={()=> {}}
        footer={false}
      >
        <ProductSelector 
          handleModal={handleMassiveDownloadModal}
        />
      </ModalContainer>
    </>
  )
}

export default ActiveProducts