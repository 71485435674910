import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';

import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";

import bwipjs from 'bwip-js';
import { jsPDF } from 'jspdf';
import { Box, Grid, Link, Input, InputLabel } from '@material-ui/core';
import { useAuth0 } from '../../hooks/react-auth0-spa';
import { DeleteProduct } from '../../api/ProductAPI';
import { Button, Modal, Popconfirm } from 'antd';
import DeleteIcon from "@material-ui/icons/Delete";
import { useAlert } from 'react-alert';
import { DataContext } from '../../context/dataContext';

const ColumnActions = ({ data, handleReload }) => {
    const [openModal, setOpenModal] = useState(false)
    const [updateValue, setUpdateValue] = useState(1)
    const { getTokenSilently } = useAuth0();
    const { data: allData, setData } = useContext(DataContext)
    const History = useHistory();
    const alert = useAlert();

    const generateBarCode = async (product, value) => {
        if (value <= 0) {
            alert.info('Debe seleccionar uno como mínimo')
            return
        }
    
        try {
            const shortId = product.SKUFulfillment.slice(-12)
            const canvas = document.createElement('canvas')
            bwipjs.toCanvas(canvas, {
                bcid: 'code128',
                text: shortId,
                scale: 4,
                height: 10,
                includetext: false,
            })
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF()
            const pageWidth = pdf.internal.pageSize.width
            const pageHeight = pdf.internal.pageSize.height
    
            const margin = 10
            const spacing = 5
            const codeWidth = (pageWidth - margin * 2 - spacing * 2) / 3
            const codeHeight = (pageHeight - margin * 2 - spacing * 9) / 10
    
            const codesPerRow = 3
            const codesPerColumn = 10
            const codesPerPage = codesPerRow * codesPerColumn
    
            for (let i = 0; i < value; i++) {
                if (i % codesPerPage === 0 && i !== 0) {
                    pdf.addPage()
                } 
                const x = margin + (i % codesPerPage % codesPerRow) * (codeWidth + spacing)
                const y = margin + Math.floor((i % codesPerPage) / codesPerRow) * (codeHeight + spacing)
    
                pdf.setFontSize(7)
                pdf.setDrawColor(200) // Gris claro
                pdf.setLineWidth(0.2)
                pdf.rect(x, y, codeWidth, codeHeight) // Borde
    
                pdf.addImage(imgData, 'PNG', x + (codeWidth - codeWidth * 0.6) / 2, y + 2, codeWidth * 0.6, codeHeight * 0.4)
    
                pdf.setFont("helvetica", "bold")
                pdf.setFontSize(8)
                pdf.text(shortId, x + codeWidth / 2, y + codeHeight * 0.45 + 5, { align: "center" })
    
                pdf.setFont("helvetica", "normal")
                pdf.setFontSize(6)
                pdf.text(product.Nombre.length > 17 ? `${product.Nombre.slice(0,17)}...` : product.Nombre, x + 2, y + codeHeight * 0.7)
                pdf.text(`${product.Largo} x ${product.Ancho} x ${product.Altura}`, x + 2, y + codeHeight * 0.8)
                pdf.text(`SKU: ${product.SKUCliente}`, x + 2, y + codeHeight * 0.9)
            }
    
            pdf.save(`${shortId}.pdf`)
            setOpenModal(false)
        } catch (err) {
            console.error('Error generating barcode:', err)
            alert.error("Error al generar etiqueta, revisa bien el producto.")
        }
    }

    const deleteProduct = async () => {
        try {
            const t = await getTokenSilently();
            const rta = await DeleteProduct(t, data._id);            
            if (rta?.code !== 200){
                alert.error(rta?.error?.message)
                return 
            }

            handleReload()
            setData({ ...allData, updateData: true })
            alert.success(rta?.res);
        } catch (err) {
            alert.error("Error al eliminar el producto");
        }
    };

    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <PrintIcon onClick={() => setOpenModal(true)} style={{ cursor:'pointer' }}/>
                </Grid>
                <Grid item xs={4}>
                    <Link
                        style={{ color:'#000' }}
                        component="button"
                        onClick={() =>
                            History.push(
                                `products/edit/${data._id}`
                            )
                        }
                    >
                        <EditIcon />
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Popconfirm
                        title="Eliminar producto"
                        onConfirm={deleteProduct}
                        okText="Eliminar"
                        cancelText="Cancelar"
                    >
                        <DeleteIcon style={{ color: "#f44336", cursor:'pointer' }} />
                    </Popconfirm>
                </Grid>
            </Grid>
            <Modal
                open={openModal}
                onCancel={()=> setOpenModal(false)}
                footer={<></>}
                title="Descargar codigos de barras"
                width={'300px'}
            >
                <Grid container>
                    <Grid item xs={12} style={{ margin:'20px 0' }}>
                        <InputLabel>
                            Para ahorrar papel, puedes seleccionar la cantidad de codigos de barras que deseas
                        </InputLabel>
                        <Input 
                            type='number' 
                            value={updateValue} 
                            onChange={(e)=> setUpdateValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            type='primary' 
                            danger style={{ width:'100%' }} 
                            onClick={()=> setOpenModal(false)}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            type='primary' 
                            style={{ width:'100%' }}
                            onClick={()=> generateBarCode(data, updateValue)}
                        >
                            Generar
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </Box>
    )
}

export default ColumnActions