import React, { useContext } from 'react'
import { QuoteDataContext } from '../../context/QuoteDataContext'

// Quote Steps
import PostalCodeQuote from './steps/PostalCodeQuote'
import SelectFavParcel from './steps/SelectFavParcel'
import FillGuideInfoForm from './steps/FillGuideInfoForm'
// import GuideSuccesScreen from '../QuoteSteps/Classic/GuideSuccesScreen'

const Quotes = ({ token, shipment, handleStep, handleFinish, integrationOrder, initAddressData, userId, handleStep3, saveData }) => {
  const { quoteStep } = useContext(QuoteDataContext)

  const { DestinationData } = saveData
  if(DestinationData){
    DestinationData['envioId'] = saveData.envioId
    // console.log('saveData =======>', saveData)
  }

  const QuoteStepComponentsList = {
    '1) Quote based on Postal Codes': (
      <PostalCodeQuote token={token} initAddressData={initAddressData} integrationOrder={integrationOrder} destinationData={DestinationData} />
    ),
    '2) Select your favorite parcel': <SelectFavParcel token={token} handleStep={handleStep} isIntegration={Boolean(integrationOrder && integrationOrder.fulfillment_info.status !== 'fulfilled')}/>,
    '3) Fill all guide info form': <FillGuideInfoForm handleStep3={handleStep3} userId={userId} token={token} shipment={shipment} destinationData={DestinationData} isIntegration={Boolean(integrationOrder && integrationOrder.fulfillment_info.status !== 'fulfilled')} />,
    // '4) Guide bought succesfully screen': <GuideSuccesScreen />
  }

  return <section className="dashboardSection ">{QuoteStepComponentsList[quoteStep]}</section>
}

export { Quotes };
