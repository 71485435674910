import React, { Fragment, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CreateShippment } from "../../api/WarehouseShippingAPI";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  TextField,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Link,
} from "@material-ui/core";
import { useAlert } from "react-alert";
import WarehouseShippingInfo from "./Info";
import { DataContext } from "./../../context/dataContext";
import { GetAllProductsByAuthId } from "../../api/ProductAPI";
import {Card} from "antd";

const initialState = {
  Codigo: "",
  Guia: {
    ProveedorDeGuia: "",
    NumeroDeGuia: "",
  },
  InfoEnvioProductos: [],
};
const initialStateProduct = {
  SKUFulfillment: "",
  Cantidad: "",
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}));

const WarehouseShippingCreate = (props) => {
  const alert = useAlert();
  const classes = useStyles();
  const History = useHistory();
  const { data, setData } = useContext(DataContext);
  const [shipment, setShipment] = useState(initialState);
  const [product, setProduct] = useState(initialStateProduct);
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { getTokenSilently, user } = useAuth0();
  const [showDialog, setShowDialog] = useState(false);
  const [shipmentType, setShipmentType] = useState("intermediary")

  const getUserProducts = async() => {
    setIsLoading(true)
    const token = await getTokenSilently();
    try {
      if(token){
        const response = await GetAllProductsByAuthId(token, user.sub)
        if(response.res) setProducts(response.res)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('Error at getUserProducts', error)
    }
  }

  useEffect(() => {
    getUserProducts()
  }, [])

  const displayInfoDialog = () => {
    setShowDialog(!showDialog);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (shipment.InfoEnvioProductos.length > 0) {
      //guardar
      const t = await getTokenSilently();
      await CreateShippment(t, shipment);
      alert.success("Tu envio al almacén fue registrado con éxito");
      await getUserProducts()
      History.push("/warehouseshippment");
    } else {
      alert.error("Debes agregar al menos un producto para continuar.");
    }
  };

  const onSubmitProduct = async (e) => {
    e.preventDefault();

    const productToSave = {
      Producto: product.SKUFulfillment,
      CantidadEnviada: product.Cantidad,
      Nombre: product.Nombre,
    };

    setShipment({
      ...shipment,
      InfoEnvioProductos: [...shipment.InfoEnvioProductos, productToSave],
    });

    setProduct(initialStateProduct);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setShipment({
      ...shipment,
      Guia: {
        ...shipment.Guia,
        [name]: value,
      },
    });
  };

  const onChangeInputProduct = (e) => {
    const { name, value } = e.target;
    let currentProduct;

    let updatedProduct = {
      ...product,
      [name]: value,
    };

    if (name === "SKUFulfillment") {
      currentProduct = products.find(
        (product) => product?.SKUFulfillment === value
      );

      if (currentProduct) {
        updatedProduct = {
          ...updatedProduct,
          Nombre: currentProduct.Nombre,
        };
      }
    }

    setProduct(updatedProduct);
  };

  return (
    <Fragment>
      <Box>
        <Card>
          <Grid item sm={12} container justify="flex-end" style={{ padding:'0 20px' }}>
            <Link component="button" variant="body2" onClick={displayInfoDialog}>
              ¿A donde dirijo el envío?
            </Link>
          </Grid>
          <CardContent>
            <form autoComplete="off" onSubmit={onSubmitProduct}>
              <Grid item xs={12}>
                <InputLabel>Productos</InputLabel>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={5}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                  >
                    <InputLabel id="producto-label">Producto</InputLabel>
                    <Select
                      name="SKUFulfillment"
                      labelId="producto-label"
                      value={product.SKUFulfillment}
                      onChange={onChangeInputProduct}
                    >
                      {products.length > 0
                        ? products.map((item, index) => (
                          <MenuItem key={index} value={item.SKUFulfillment}>
                            {item.SKUCliente} - {item.Nombre}
                          </MenuItem>
                        ))
                      : (
                        <MenuItem>
                          No hay productos
                        </MenuItem>
                      )
                    }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    type="number"
                    name="Cantidad"
                    label="Cantidad"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.Cantidad}
                    onChange={onChangeInputProduct}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    type="submit"
                    className={classes.textField}
                    variant="outlined"
                    color="primary"
                  >
                    Agregar producto
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={3} width={1}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Producto</TableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Cantidad</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shipment.InfoEnvioProductos.map((item, index) => (
                          <TableRow key={index} id={index}>
                            <TableCell>{item.Producto}</TableCell>
                            <TableCell>{item.Nombre}</TableCell>
                            <TableCell>{item.CantidadEnviada}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </form>
            <form autoComplete="off" onSubmit={onSubmit}>
                  <Grid item xs={5}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        required={true}
                      >
                        <InputLabel id="producto-label">Metodo de envio</InputLabel>
                        <Select
                          name="shipmentType"
                          labelId="type-label"
                          value={shipmentType}
                          onChange={(e)=>setShipmentType(e.target.value)}
                        >
                          <MenuItem value={"personally"}>Lo enviaré yo mismo</MenuItem>
                          <MenuItem value={"intermediary"}>Lo enviaré por paquetería</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
              {
                shipmentType === 'intermediary'
                && (
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="ProveedorDeGuia"
                          label="Proveedor de la guía"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          required={true}
                          value={shipment.Guia.ProveedorDeGuia}
                          onChange={onChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="NumeroDeGuia"
                          label="Numero de guía"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          required={true}
                          value={shipment.Guia.NumeroDeGuia}
                          onChange={onChangeInput}
                        />
                      </Grid>
                    </Grid>
                )
              }
            <Grid item xs={12} container justify="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => History.push("/warehouseshippment")}
                className={classes.textField}
              >
                Cancelar
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.textField}
              >
                Guardar
              </Button>
            </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
      <WarehouseShippingInfo
        showDialog={showDialog}
        displayInfoDialog={displayInfoDialog}
      />
    </Fragment>
  );
};

export default WarehouseShippingCreate;
