import { Button, Card, Modal } from "antd"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Steps } from 'antd'
import SelectedProducts from './SelectedProducts'
import { Quotes } from "../quoter/Quotes"
import {ArrowRightOutlined} from "@ant-design/icons";

const RateModal = ({ data: user })=> {

    const alert = useAlert()
    const [openModal, setOpenModal] = useState(false)
    const [userId, setUserId] = useState('')
    const [token, setToken] = useState('')
    const [step, setStep] = useState({ step: 0, description: 'select products' })
    const [shipment, setShipment] = useState(null)
    const [isFinish, setIsFinish] = useState(false)

    const handleModal = ()=> setOpenModal(!openModal)

    const resetFields = () => {
        setUserId(user?.user)
        setToken(user?.ApiClientsV2[0]?.prod_token?.token)
        setStep({ step: 0, description: 'select products' })
        setShipment(null)
        setIsFinish(false)
    }

    const handleStep1 = () => setStep({ step: 1, description: 'rate' })
    const handleStep2 = () => setStep({ step: 2, description: 'generate' })
    const handleStep3 = () => handleFinish()

    const handleFinish = ()=> {
        setIsFinish(true)
        setTimeout(()=> {
            handleModal()
        }, 2000)    
    }

    const handleShipment = (shipment)=> {
        if (!shipment || shipment?.InfoEnvioProductos.length === 0){
            alert.info("Debes seleccionar un producto")
            return 
        }

        setShipment(shipment)
    }

    const handleTransition = (step)=> {
        switch(step){
            case 'select products':
                return (
                    <SelectedProducts 
                        userId={userId}
                        handleShipment={handleShipment} 
                        handleStep={handleStep1}
                        saveData={user} // data -> [ Ordenes de envio ]
                    />
                )
            case 'rate':
            case 'generate':
                return (
                    <Quotes
                        userId={userId}
                        token={token}
                        shipment={shipment}
                        handleStep={handleStep2} 
                        handleFinish={handleFinish}
                        handleStep3={handleStep3}
                        saveData={user}
                    />
                )
        }
    }

    useEffect(()=> {
        resetFields()
    }, [user, openModal])

    return (
        <>
            <Button disabled={user.ApiClientsV2.length === 0} icon={<ArrowRightOutlined />} onClick={handleModal}>
                Cotizar
            </Button>
            <Modal
                open={openModal}
                title={false}
                width={'90%'}
                styles={{ body: { minHeight: '90vh', padding: '20px'}}}
                onCancel={handleModal}
                footer={false}
            >
                <Card style={{ boxShadow:'0 0 10px 2px #0001', marginBottom: '25px' }}>
                    <Steps 
                        current={step.step}
                        status={isFinish ? "finish" : "process"}
                        items={[
                            {
                            title: 'Seleccionar producto',
                            description: 'Selecciona los productos que contiene el paquete',
                            },
                            {
                            title: 'Cotizar',
                            description: 'Selecciona tu paqueteria favorita',
                            },
                            {
                            title: 'Generar guia',
                            description: 'Genera tus guias a tus destinos favoritos',
                            },
                        ]}
                    />
                </Card>
                {/* <Card style={{ boxShadow:'0 0 10px 2px #0001' }}> */}
                    {handleTransition(step.description)}
                {/* </Card> */}
            </Modal>
        </>
    )
}

export default RateModal