import { axiosBase as axios } from "./AxiosConfig";

export const GetAllProducts = async (token) => {
  try {
    const response = await axios.get("/products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetAllProductsByAuthId = async (token, authId) => {
  try {
    const response = await axios.get(`/allproducts/${authId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const CreateProduct = async (token, product) => {
  try {
    const response = await axios.post("/products", product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetProductBySKU = async (token, SkuFulfillment) => {
  try {
    const response = await axios.get(`/products/${SkuFulfillment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const UpdateProduct = async (token, SkuFulfillment, product) => {
  try {
    const response = await axios.put(`/products/${SkuFulfillment}`, product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const DeleteProduct = async (token, SkuFulfillment) => {
  try {
    const response = await axios.delete(`/products/${SkuFulfillment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const UpdateQTYProduct = async (token, data) => {
  try {
    const response = await axios.put(`/product/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const CreateMassiveProducts = async (token, products) => {
  try {
    const response = await axios.post("/products/create/massive", { productsArray: products }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

