import React from 'react';
import CustonTabs from '../../components/tables/CustonTabs';
import ActiveProducts from './ActiveProducts';
import InactiveProducts from './InactiveProducts';

const Products = () => {
  return (
    <CustonTabs 
      tabs={
        [
          {
            title: 'Activos',
            component: <ActiveProducts />
          },
          {
            title: 'Inactivos',
            component: <InactiveProducts />
          },
        ]
      }
    />
  )
}

export default Products