import React, { Fragment, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "../../hooks/react-auth0-spa"
import {
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core"
import { GetAllProductsByAuthId } from "../../api/ProductAPI"
import bwipjs from 'bwip-js';
import { jsPDF } from 'jspdf';
import { useAlert } from "react-alert"
import { axiosBase as axios } from '../../api/AxiosConfig'


const initialState = {
  Codigo: "",
  Guia: {
    Proveedor: "",
    Numero: "",
  },
  InfoEnvioProductos: [],
}
const initialStateProduct = {
  SKUFulfillment: "",
  Cantidad: "",
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}))

const ProductSelector = ({ handleModal }) => {
  const classes = useStyles()
  const alert = useAlert();
  const [shipment, setShipment] = useState(initialState)
  const [product, setProduct] = useState(initialStateProduct)
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { user, getTokenSilently } = useAuth0()

  const getUserProducts = async() => {
    setIsLoading(true)
    const token = await getTokenSilently()
    try {
      if(token){
        const response = await GetAllProductsByAuthId(token, user?.sub)
        if(response) setProducts(response.res)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('Error at getUserProducts', error)
    }
  }

  const onChangeInputProduct = (e) => {
    const { name, value } = e.target
    let currentProduct

    let updatedProduct = {
      ...product,
      [name]: value,
    }

    if (name === "SKUFulfillment") {
      currentProduct = products.find((product) => product.SKUFulfillment === value)
      if (currentProduct) {
        updatedProduct = {
          ...updatedProduct,
          Nombre: currentProduct.Nombre,
        }
      }
    }

    setProduct(updatedProduct)
  }

  const onSubmitProduct = async (e) => {
    e.preventDefault()

    const productToSave = {
      Producto: product.SKUFulfillment,
      CantidadEnviada: product.Cantidad,
      Nombre: product.Nombre,
    }

    setShipment({
      ...shipment,
      InfoEnvioProductos: [...shipment.InfoEnvioProductos, productToSave],
    })

    setProduct(initialStateProduct)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!shipment || shipment?.InfoEnvioProductos.length === 0){
        alert.info("Debes seleccionar un producto")
        return 
    }

    const shipmentIds = shipment?.InfoEnvioProductos?.map(item => item.Producto)

    try {
        const userToken = await getTokenSilently()
        const { data } = await axios.post(
            `/allProductsOnArray`, 
            shipmentIds, 
            { headers: { Authorization: `Bearer ${userToken}` }}
        )

        const pdf = new jsPDF()
        const pageWidth = pdf.internal.pageSize.width
        const pageHeight = pdf.internal.pageSize.height

        const margin = 10
        const spacing = 5
        const codeWidth = (pageWidth - margin * 2 - spacing * 2) / 3
        const codeHeight = (pageHeight - margin * 2 - spacing * 9) / 10

        const codesPerRow = 3
        const codesPerColumn = 10
        const codesPerPage = codesPerRow * codesPerColumn

        let acum = 0
        for (let i2 = 0; i2 < shipment?.InfoEnvioProductos?.length; i2++){
            const currentProduct = shipment?.InfoEnvioProductos[i2]
            const currentProductExtended = data?.res?.find(item => item?.SKUFulfillment === currentProduct?.Producto)

            for (let i = 0; i < Number(currentProduct?.CantidadEnviada); i++){
                if (acum % codesPerPage === 0 && acum !== 0) {
                    pdf.addPage()
                } 
                const x = margin + (acum % codesPerPage % codesPerRow) * (codeWidth + spacing)
                const y = margin + Math.floor((acum % codesPerPage) / codesPerRow) * (codeHeight + spacing)

                const shortId = currentProductExtended?.SKUFulfillment?.slice(-12)
                const canvas = document.createElement(`canvas`)
                bwipjs.toCanvas(canvas, {
                    bcid: 'code128',
                    text: shortId,
                    scale: 4,
                    height: 10,
                    includetext: false,
                })
                const imgData = canvas.toDataURL('image/png')
    
                pdf.setFontSize(7)
                pdf.setDrawColor(200) // Gris claro
                pdf.setLineWidth(0.2)
                pdf.rect(x, y, codeWidth, codeHeight) // Borde
    
                pdf.addImage(imgData, 'PNG', x + (codeWidth - codeWidth * 0.6) / 2, y + 2, codeWidth * 0.6, codeHeight * 0.4)
    
                pdf.setFont("helvetica", "bold")
                pdf.setFontSize(8)
                pdf.text(shortId, x + codeWidth / 2, y + codeHeight * 0.45 + 5, { align: "center" })
    
                pdf.setFont("helvetica", "normal")
                pdf.setFontSize(6)
                pdf.text(currentProductExtended.Nombre.length > 17 ? `${currentProductExtended.Nombre.slice(0,17)}...` : currentProductExtended.Nombre, x + 2, y + codeHeight * 0.7)
                pdf.text(`${currentProductExtended?.Largo} x ${currentProductExtended?.Ancho} x ${currentProductExtended?.Altura}`, x + 2, y + codeHeight * 0.8)
                pdf.text(`SKU: ${currentProductExtended?.SKUCliente}`, x + 2, y + codeHeight * 0.9)
                acum++
            }

        }
        pdf.save(`${'qr-codes'}.pdf`)
        setShipment(initialState)
        handleModal()
    } catch (err) {
        console.error('Error generating massive codes:', err)
        alert.error("Error al generar etiquetas masivas")
    }
  }

  useEffect(() => {
    getUserProducts()
  }, [])

  return (
    <Fragment>
      <Grid container>
      </Grid>
      <Box>
        <Card>
          <CardContent>
            <form autoComplete="off" onSubmit={onSubmitProduct}>
              <Grid item xs={12}>
                <InputLabel>Productos</InputLabel>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={5}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                  >
                    <InputLabel id="producto-label">Producto</InputLabel>
                    <Select
                      name="SKUFulfillment"
                      labelId="producto-label"
                      value={product.SKUFulfillment}
                      onChange={onChangeInputProduct}
                    >
                        {
                            isLoading
                                ? <p>Cargando ...</p>
                                : products.length == 0
                                    ? (
                                        <p>No hay productos</p>
                                    )
                                    : products.map((item, index) => (
                                        <MenuItem key={index} value={item.SKUFulfillment}>
                                        {item.SKUCliente} - {item.Nombre}
                                        </MenuItem>
                                    ))
                        }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    type="number"
                    name="Cantidad"
                    label="Cantidad"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.Cantidad}
                    onChange={onChangeInputProduct}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    type="submit"
                    className={classes.textField}
                    variant="outlined"
                    color="primary"
                  >
                    Agregar producto
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={3} width={1}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Producto</TableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Cantidad</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shipment.InfoEnvioProductos.map((item, index) => (
                          <TableRow key={index} id={index}>
                            <TableCell>{item.Producto}</TableCell>
                            <TableCell>{item.Nombre}</TableCell>
                            <TableCell>{item.CantidadEnviada}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </form>
            <form autoComplete="off" onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    disabled={shipment.InfoEnvioProductos.length === 0}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.textField}
                  >
                    Descargar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  )
}

export default ProductSelector
