import {
    BarcodeOutlined,
    ProductOutlined,
    ScanOutlined,
    SendOutlined,
    ShoppingCartOutlined,
    TruckOutlined,
    EditOutlined,
    HistoryOutlined,
    HarmonyOSOutlined, HomeOutlined
} from "@ant-design/icons";

export const NavbarRoutes = {

    // ---- RUTAS NORMALES ---- //
    Products: {
        title: "Mis productos",
        route: "/products",
        type: "item",
        icon: <BarcodeOutlined />,
        role: "COMMON",
        description: "Administra los productos que vendes"
    },
    WarehouseShipment: {
        title: "Envíos al almacén",
        route: "/warehouseshippment",
        type: "item",
        icon: <ShoppingCartOutlined />,
        role: "COMMON",
        description: "Monitorea y envía tus productos al almacén"
    },
    OutboundShipment: {
        title: "Ordenes de envío",
        route: "/outboundshipments",
        type: "item",
        icon: <SendOutlined />,
        role: "COMMON",
        description: "Crea y visualiza los despachos de tus productos"
    },

    // ----- RUTAS DE ADMIN ----- //

    WarehouseReceive: {
        title: "Recibir mercancía",
        route: "/warehousereceive",
        type: "item",
        icon: <ScanOutlined />,
        role: "ADMIN",
        description: "Escanea los productos para ingresarlos en el almacén"
    },

    WarehouseOutput: {
        title: "Enviar mercancía",
        type: "subitem",
        children: [
            { title: "Ver salidas programadas", route: "/warehouseoutput", description: "Realiza los despachos que los clientes realizaron manualmente" },
            { title: "Despachar manualmente", route: "/warehouseoutput/dispatch", description: "Busca al cliente y despacha una guia desde su cuenta de Drenvio" },
        ],
        icon: <TruckOutlined />,
        role: "ADMIN",
    },
    WarehouseProducts: {
        title: "Tabla de productos",
        route: "/warehouseproducts",
        type: "item",
        icon: <ProductOutlined />,
        role: "ADMIN",
        description: "Inventario de la bodega"
    },
    RequestUpdateProducts: {
        title: "Solicitudes de edición",
        route: "/updateproducts",
        type: "item",
        icon: <EditOutlined  />,
        role: "ADMIN",
        description: "Solicitud de cambios hecha por usuarios"
    },
    Movements: {
        title: "Movimientos",
        route: "/movements",
        type: 'item',
        icon: <HistoryOutlined />,
        role: "ADMIN",
        description: "Seguimiento de la plataforma"
    },
    // TestSection: {
    //     title: "Sección de pruebas",
    //     route: "/testing",
    //     type: "item",
    //     icon: <HarmonyOSOutlined />,
    //     role: 'ADMIN',
    //     description: "Sección destinada para pruebas en producción"
    // }

}