import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/History";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import PrivateRoute from "./components/session/PrivateRoute";
import Login from "./components/session/Login";
import Logout from "./components/session/Logout";

// Context Components
import { Auth0Provider } from "./hooks/react-auth0-spa";
import config from "./auth0_config.json";
import { DataContextProvider } from "./context/dataContext";
import { QuoteDataContextProvider } from "./context/QuoteDataContext";
import { CountriesContextProvider } from "./context/CountriesContext";

/* Components import */
import CustomAppBar from "./components/template/CustomAppBar";

/* Pages import */
import Index from "./pages/Index";
//Products
import Products from "./pages/products/Index";
import ProductEdit from "./pages/products/Edit";
import ProductCreate from "./pages/products/Create";

//Warehouseshippment
import WarehouseShippingIndex from "./pages/warehouseShipping/Index";
import WarehouseShippingCreate from "./pages/warehouseShipping/Create";

import WarehouseReceiveIndex from "./pages/warehouseReceive/Index";

import WarehouseOutputScan from "./pages/warehouseOutput/Scan";
import WarehouseOutputIndex from "./pages/warehouseOutput/Index";
import ManualDispatch from './pages/manualDispatch/Index';
import OutboundShipments from "./pages/outboundShipments";
import OutboundShipmentsCreate from "./pages/outboundShipments/createOutboundShipments";
import OutboundShipmentDetail from "./pages/outboundShipments/outboundShipmentDetail";
import Home from "./pages/home";
import ProductsAdmin from "./pages/warehouseProducts/Index";
import UpdateProducts from "./pages/updateProducts/index";
import Movements from "./pages/tracking/Index";
import OutboundShipmentsTabs from "./pages/outboundShipments";
import TestSectionTabs from "./pages/testSection";
import CreateWithXLSX from "./pages/products/CreateWithXLSX";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2e1d6e",
    },
  },
});

function App() {
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/Home" component={Home} />
        <Auth0Provider
          domain={config.domain}
          client_id={config.clientId}
          redirect_uri={window.location.origin}
          onRedirecrCallback={onRedirectCallback}
          audience={config.audience}
        >
          <DataContextProvider>
            <CountriesContextProvider>
              <QuoteDataContextProvider>
                <CustomAppBar>
                  <MuiThemeProvider theme={theme}>
                    <PrivateRoute exact path="/" component={Index} />
                    <PrivateRoute exact path="/warehouseproducts" component={ProductsAdmin} />
                    <PrivateRoute exact path="/Index" component={Index} />
                    <PrivateRoute exact path="/products" component={Products} />
                    <PrivateRoute exact path="/testing" component={TestSectionTabs} />
                    <PrivateRoute
                      exact
                      path="/products/create"
                      component={ProductCreate}
                    />
                    <PrivateRoute
                      exact
                      path="/products/create/masive"
                      component={CreateWithXLSX}
                    />
                    <PrivateRoute
                      exact
                      path="/products/edit/:SkuFulfillment"
                      component={ProductEdit}
                    />
                    <PrivateRoute
                      exact
                      path="/movements"
                      component={Movements}
                    />
                    <PrivateRoute
                      exact
                      path="/products/delete/:id"
                      component={ProductEdit}
                    />
                    <PrivateRoute
                      exact
                      path="/warehouseshippment"
                      component={WarehouseShippingIndex}
                    />
                    <PrivateRoute
                      exact
                      path="/warehouseshippment/create"
                      component={WarehouseShippingCreate}
                    />
                    <PrivateRoute
                      exact
                      path="/warehousereceive"
                      component={WarehouseReceiveIndex}
                    />
                    <PrivateRoute
                      exact
                      path="/warehouseoutput"
                      component={WarehouseOutputIndex}
                    />
                    <PrivateRoute
                      exact
                      path="/warehouseoutput/dispatch"
                      component={ManualDispatch}
                    />
                    <PrivateRoute
                      exact
                      path="/warehouseoutput/scan/:idEnvio"
                      component={WarehouseOutputScan}
                    />
                    <PrivateRoute
                      exact
                      path="/outboundshipments"
                      component={OutboundShipmentsTabs}
                    />
                    <PrivateRoute
                      exact
                      path="/outboundshipments/create"
                      component={OutboundShipmentsCreate}
                    />
                    <PrivateRoute
                      exact
                      path="/outboundshipments/detail/:id"
                      component={OutboundShipmentDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/updateproducts"
                      component={UpdateProducts}
                    />
                    <Route path="/login" component={Login} exact />
                    <Route path="/logout" component={Logout} exact />
                  </MuiThemeProvider>
                </CustomAppBar>
              </QuoteDataContextProvider>
            </CountriesContextProvider>
          </DataContextProvider>
        </Auth0Provider>
      </Switch>
    </Router>
  );
}

export default App;
