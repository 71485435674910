import React, { useState, useEffect, Fragment } from "react";
import { GetProductBySKU, UpdateProduct } from "../../api/ProductAPI";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Button,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from "react-images-uploading";
import { useAlert } from "react-alert";

const initialState = {
  SKUCliente: "",
  Nombre: "",
  PesoKG: "",
  Largo: "",
  Ancho: "",
  Altura: "",
  Descripcion: "",
  Fotografia: "",
  Fotografia64: "",
  ValorMercancia: "",
};

const numberValues = ["PesoKG","Largo", "Ancho", "Altura", "ValorMercancia", "VentaAmazon", "VentaEcommerce", "VentaMeli"];

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
}));

const ProductEdit = (props) => {
  const { getTokenSilently } = useAuth0();
  const classes = useStyles();
  const History = useHistory();
  const [product, setProduct] = useState(initialState);
  const [images, setImages] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    async function fetchData() {
      const t = await getTokenSilently();
      const productRes = await GetProductBySKU(
        t,
        props.match.params.SkuFulfillment
      );
      setProduct(productRes);
    }

    fetchData();
  }, []);

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProduct({
      ...product,
      Fotografia: "",
      Fotografia64: imageList.length > 0 ? imageList[0].data_url : "",
    });
  };

  const removeFotografia = () => {
    setProduct({
      ...product,
      Fotografia: "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (product.Fotografia || product.Fotografia64) {
      try {
        const t = await getTokenSilently();
        const rta = await UpdateProduct(t, props.match.params.SkuFulfillment, product);

        if (rta?.code !== 200){
          alert.info(rta?.error?.message);
          return 
        }

        alert.success(rta?.res);
        History.push("/products");
      } catch (err){
        alert.info(
          "Se genero un error al intentar editar el producto"
        );
      }
    } else {
      alert.info(
        "Debes seleccionar una fotografía, para identificar mejor tu producto"
      );
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    if (numberValues.includes(name)){
      if (/^\d*\.?\d*$/.test(value)) {
        setProduct({ ...product, [name]: value });
      }
    } else {
      if(name === 'Descripcion'){
        if(value.length < 80){
          setProduct({ ...product, [name]: value });
        } else return
      }
      setProduct({ ...product, [name]: value })
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h5" gutterBottom>
            Editar producto
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Card>
          <CardContent>
            <form autoComplete="off" onSubmit={onSubmit}>
            <Paper elevation={3} style={{ padding: '16px', margin: '16px 0', backgroundColor: '#e3f2fd' }}>
              <Typography variant="h6" gutterBottom>
                Información Importante
              </Typography>
              <Typography variant="body1">
                Para editar el nombre de los productos, se debe enviar una solicitud que debe ser revisada y aprobada por la administración. Las demás propiedades pueden ser modificadas directamente.  
              </Typography>
            </Paper>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="SKUCliente"
                    label="SKU"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.SKUCliente}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Nombre"
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.Nombre}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Descripcion"
                    label="Descripción corta"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.Descripcion}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Largo"
                    label="Largo"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Largo}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Ancho"
                    label="Ancho"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Ancho}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Altura"
                    label="Altura"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Altura}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="PesoKG"
                    label="Peso"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">KG</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.PesoKG}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaAmazon"
                    label="Venta Amazon (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaAmazon}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaMeli"
                    label="Venta MercadoLibre (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaMeli}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaEcommerce"
                    label="Venta Ecommerce (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaEcommerce}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="ValorMercancia"
                    label="Costo"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.ValorMercancia}
                    onChange={onChangeInput}
                  />
                  <label>
                    El monto máximo a cubrir por el seguro es de $ 2,000
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.textField}>
                    Fotografía del producto
                  </InputLabel>
                  {product.Fotografia.length > 0 ? (
                    <div className="upload__image-wrapper">
                      <Box mt={1}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={removeFotografia}
                        >
                          Quitar imagen
                        </Button>
                      </Box>
                      <Box mt={2}>
                        <div className="image-item">
                          <img
                            src={product.Fotografia}
                            alt=""
                            width="200"
                            height="auto"
                          />
                        </div>
                      </Box>
                    </div>
                  ) : (
                    <ImageUploading
                      value={images}
                      onChange={onChangeImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          <Box mt={1}>
                            {imageList.length > 0 ? (
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onImageRemoveAll}
                              >
                                Quitar imagen
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Selecciona una imagen
                              </Button>
                            )}
                          </Box>
                          <Box mt={2}>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image.data_url}
                                  alt=""
                                  width="200"
                                  height="auto"
                                />
                              </div>
                            ))}
                          </Box>
                        </div>
                      )}
                    </ImageUploading>
                  )}
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => History.push("/products")}
                  >
                    Cancelar
                  </Button>
                  &nbsp;
                  <Button type="submit" variant="contained" color="primary">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ProductEdit;
